/**
 * configuration applied when BROCOLI_API_MODE = staging
 */
module.exports = {
  useShell: [
    'bb-default',
    {
      mode: 'universes',
      universes: [
        {
          type: 'tag',
          tagId: '62b9ac5379c36686dfb77854',
          i18n: {
            fr: {
              title: 'Sélection du mois',
              description: `Cultivons l'optimisme`,
            },
          },
          heading: {
            color: 'primary',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
            backgroundImage(context) {
              return context.$voicer.isMobile
                ? require('~/assets/images/selectionmois-mobile.png')
                : require('~/assets/images/selectionmois.png')
            },
          },
        },
        {
          type: 'tag',
          tagId: '62b9ac4379c3669fc7b7784e',
          i18n: {
            fr: {
              title: 'Tous les contenus',
              description: `Tous les épisodes de Second Souffle`,
            },
          },
          heading: {
            color: 'primary',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
            backgroundImage(context) {
              return context.$voicer.isMobile
                ? require('~/assets/images/touslescontenus-mobile.png')
                : require('~/assets/images/touslescontenus.png')
            },
          },
        },
      ],
    },
  ],
}
