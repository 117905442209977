<template>
  <div
    class="w-block__pre-heading"
    :class="{ 'ml-4 mr-4 mt-3': $voicer.isMobile }"
  >
    <!-- pre-heading block -->
    <v-container>
      <v-sheet color="#e3ebf2" dark class="pa-3">
        <p class="body-2">
          <img
            :src="require('~/assets/images/logo-campus.png')"
            height="47"
            alt="logo-spotify"
            class="mr-4"
          />
          <br />
          Second Souffle, votre podcast d'inspiration
        </p>
      </v-sheet>
    </v-container>
    <w-block name="cta" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      userName: 'auth/name',
    }),
  },
}
</script>

<style lang="scss" scoped>
.message_welcome_sir {
  border-radius: 6px !important;
}
p {
  color: #004079;
  text-align: center;
  font-size: 17px !important;
  font-weight: 800;
}
</style>
