const $merge = require('lodash.merge')

const config = require('./config.base')
const loadShell = require('./utils/voicerconfig/load-shell')

const configEnvironments = {
  development: require('./config.development'),
  staging: require('./config.staging'),
  production: require('./config.production'),
  next: require('./config.next'),
}

let configLocal = require('./utils/voicerconfig/load-local')
let envConfig = null

if (process.env.ADD_CONFIG_OPTIONS) {
  try {
    console.log(`🤓 use environment options`)
    envConfig = JSON.parse(process.env.ADD_CONFIG_OPTIONS)
  } catch (error) {
    console.error(`Invalid environment options`)
    console.error(`You passed`, process.env.ADD_CONFIG_OPTIONS)
    console.error(error)
    process.exit(1)
  }
}

const transformConfig = (config) => {
  if (config.useShell) {
    config = loadShell(config)
  }
  return config
}

/**
 * default configuration file
 * common no matter the BROCOLI_API_MODE
 * you can override properties in
 * config.[BROCOLI_API_MODE].js configurations files
 */
const endConfig = $merge({},
  config, {
    /**
     * common configuration here
     */
     siteId: '5fd0e96183187d8591a2d088',
     authMode: [
       'local',
       {
         mode: 'otp',
       },
     ],
     enableSplashScreen: 'wavy',
     showCardDescription: true,
     enableSplashScreen: 'wavy',
     enableExperimentalBlocks: true,
     disabledComponents: ['wButtonLike', 'wTagLikeCount','wCardsEngagementLabels'],
     metadatas: {
       name: 'Second Souffle',
       colorPrimary: '#004079',
       colorAccent: '#FF8732',
     },
     analytics: {
       ga: 'UA-181785380-4',
       matomo: '22',
       mixpanel: '37e014f0d4ba1e9df449b5b59a3faac09',
     },
     useShell: [
      'bb-default',
      {
        mode: 'universes',
        universes: [
          {
            type: 'tag',
            tagId: '62b5c258d7e90c963e0ab327',
            i18n: {
              fr: {
                title: 'Sélection du mois',
                description: `De belles histoires`,
              },
            },
            heading: {
              color: 'primary',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
              backgroundImage(context) {
                return context.$voicer.isMobile
                  ? require('~/assets/images/selectionmois-mobile.png')
                  : require('~/assets/images/selectionmois.png')
              },
            },
            },
          {
            type: 'tag',
            tagId: '62b5c262b83b8e1623fb004c',
            i18n: {
              fr: {
                title: 'Tous les contenus',
                description: `Tous les épisodes de Second Souffle`,
              },
            },
            heading: {
              color: 'primary',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
              backgroundImage(context) {
                return context.$voicer.isMobile
                  ? require('~/assets/images/touslescontenus-mobile.png')
                  : require('~/assets/images/touslescontenus.png')
              },
            },
          },
        ],
        view: {
          allowHeadingOnMobile: false,
          featuredContents: false,
        },
      },
    ],
  },
  configEnvironments[process.env.BROCOLI_API_MODE],
  configLocal,
  envConfig
)

module.exports = transformConfig(endConfig)
